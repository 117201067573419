import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './src/styles/global.css';

import React from 'react';
import { SnackbarProvider } from 'notistack';

export const wrapPageElement = ({ element }) => (
  <SnackbarProvider maxStack={1}>{element}</SnackbarProvider>
);
